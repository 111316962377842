// libs
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// constants
import { WIDGET_TYPES, PLACE_HOLDER_COLORS } from './constants';
import { PINNED_PROD_IDS_SEARCHTERM } from 'shared/store/listing/helpers';
import { FILTER_IDENTIFIER, IS_FILTERED_URL } from 'shared/layouts/Listing/Api/constants';

// def
import { ILandingPageWidget, IWidgetItems, WidgetTrackingData } from './types';

// helpers
import { log } from 'shared/helpers/newRelic';
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';
import logger from 'shared/logger';
import { generateNykaaUrl } from 'shared/helpers/utils/url';
import { nykaaRxp } from 'shared/helpers/utils';

const MODULE_IDENTRIFIER = 'Impression Tracking';
const ADPLATFORM_MALFORMED_URL = 'Adplatform Malformed Url';

export const sortByKey = (parent: IWidgetItems[], key: string): IWidgetItems[] => {
  let sortedArray: IWidgetItems[];
  sortedArray = parent.slice().sort(function (a, b) {
    return (get(a, key) as number) - (get(b, key) as number);
  });
  return sortedArray;
};

export const generateTrackingParams = (
  inventoryName: string,
  position: number,
  trackingParams: string
) => {
  const inventory_name = inventoryName.replace(new RegExp(/([.])+/g), ',').trim();
  return `intcmp=${inventory_name},${position},${trackingParams}`;
};

interface FilteredUrlParams {
  url: string;
  inventoryName: string;
  position: number;
  trackingParams: string;
  pinnedProductsIds?: string;
}

export const getIsFilteredUrlParams = ({
  url,
  inventoryName,
  position,
  trackingParams,
  pinnedProductsIds,
}: FilteredUrlParams) => {
  try {
    if (nykaaRxp.test(url) === false && __SERVER__) {
      logger.error({
        message: 'MALFORMED_ADPLATFORM_REDIRECTION',
        METHOD_NAME: 'getIsFilteredUrlParams',
        info: {
          url,
          inventoryName,
          position,
          trackingParams,
          pinnedProductsIds,
        },
      });
    }

    const newUrl = new URL(generateNykaaUrl(url));
    const searchParams = newUrl.searchParams;

    if (searchParams.has(FILTER_IDENTIFIER)) {
      return `&${IS_FILTERED_URL}=true`;
    }

    return '';
  } catch (error) {
    if (__SERVER__) {
      // @ts-ignore
      logger.error({
        ERROR: 'MALFORMED_ADPLATFORM_REDIRECTION',
        METHOD_NAME: 'getIsFilteredUrlParams',
        info: {
          url,
          inventoryName,
          position,
          trackingParams,
          pinnedProductsIds,
        },
      });
    } else {
      log({
        errTitle: 'Malformed Adplatform Redirection',
        tags: { [TAG_IDENTIFIER.MODULE]: ADPLATFORM_MALFORMED_URL },
        extraInfo: url,
      });
    }
    return '';
  }
};

export const generateTrackingUrl = (
  url: string,
  inventoryName: string,
  position: number,
  trackingParams: string,
  pinnedProductsIds?: string
) => {
  if (!url) return '';

  const paramsExp = new RegExp(/([?])+/g);
  let trackingUrl = paramsExp.test(url)
    ? `${url}&${generateTrackingParams(inventoryName, position, trackingParams)}`
    : `${url}?${generateTrackingParams(inventoryName, position, trackingParams)}`;

  if (pinnedProductsIds) {
    trackingUrl += `&${PINNED_PROD_IDS_SEARCHTERM}=${pinnedProductsIds}`;
  }

  trackingUrl += getIsFilteredUrlParams({
    url,
    inventoryName,
    position,
    trackingParams,
    pinnedProductsIds,
  });

  return trackingUrl;
};

export const getMetaData = (landingPageData: ILandingPageWidget[]) => {
  const landingPageWidget = landingPageData.find(
    (widgetData) =>
      widgetData?.widget_data?.wtype === WIDGET_TYPES.SEO_DATA ||
      widgetData?.widgetData?.widgetType === WIDGET_TYPES.SEO_DATA
  );
  return get(
    landingPageWidget,
    'widget_data.parameters',
    get(landingPageWidget, 'widgetData.params', {})
  );
};

const sortedWidgetChildren = (widget: ILandingPageWidget): IWidgetItems[] => {
  return sortByKey(get(widget, 'widget_data.children', []), 'positionInParent');
};

interface IGetCarouselImagesProps {
  widget: ILandingPageWidget;
  options: {
    urlSource: string;
    aspectRatioSrc: string;
    defaultAspectRatio: number;
    fallBackUrlSource?: string;
    fallBackAspectRatioSrc?: string;
    fallBackDefaultAspectRatio?: number;
  };

  inventoryName: string;
}

export const getCarouselImages = ({ widget, options, inventoryName }: IGetCarouselImagesProps) =>
  sortedWidgetChildren(widget).map((child: IWidgetItems) => {
    const data = get(child, 'children[0].source', {});
    const defaultURLSource = get(data, options.urlSource, '');

    const action_url = generateTrackingUrl(
      child.parameters.web_action_url,
      inventoryName,
      child.positionInParent,
      child.parameters.tracking_parameters
    );

    const trackingData = get(child, 'trackingData');

    if (defaultURLSource) {
      return {
        url: defaultURLSource,
        aspect_ratio: get(data, options.aspectRatioSrc, options.defaultAspectRatio),
        action_url,
        trackingData,
      };
    } else if (
      options.fallBackUrlSource &&
      options.fallBackAspectRatioSrc &&
      options.fallBackDefaultAspectRatio
    ) {
      return {
        url: get(data, options.fallBackUrlSource, ''),
        aspect_ratio: get(data, options.fallBackAspectRatioSrc, options.fallBackDefaultAspectRatio),
        action_url,
        trackingData,
      };
    }
    return {};
  });

export const getPlaceHolderColor = (id?: number) => {
  const placeholderKeys = Object.keys(PLACE_HOLDER_COLORS);
  const indx = id
    ? id % placeholderKeys.length
    : Math.floor(Math.random() * placeholderKeys.length);

  return get(PLACE_HOLDER_COLORS, placeholderKeys[indx]);
};

export const getTransactionId = (item: ILandingPageWidget | IWidgetItems) => {
  if (get(item, 'transaction_id')) {
    return get(item, 'transaction_id');
  }

  if (get(item, 'widget_data.wid')) {
    return `wid-${get(item, 'widget_data.wid')}`;
  }

  if (get(item, 'wid')) {
    return `wid-${get(item, 'wid')}`;
  }

  if (get(item, 'id')) {
    return `pid-${get(item, 'id')}`;
  }

  log({
    errTitle: 'Transaction ID Missing',
    tags: { [TAG_IDENTIFIER.MODULE]: MODULE_IDENTRIFIER },
    extraInfo: item,
  });

  return '';
};

export const transformLandingData = (
  landingPageData: ILandingPageWidget[]
): ILandingPageWidget[] => {
  if (isEmpty(landingPageData)) return [];

  return landingPageData.map((item) => {
    const trackingDataParent: WidgetTrackingData = {
      position1: get(item, 'position'),
      position2: get(item, 'widget_data.positionInParent'),
      transaction_id: getTransactionId(item),
      namespace0: get(item, 'inventory_page_type', ''),
      namespace1: get(item, 'inventory_page_section', ''),
      namespace2: get(item, 'parameters.tracking_parameters', ''),
      inventoryPageData: get(item, 'inventory_page_data'),
    };

    const childItems = get(item, 'widget_data.children', []);

    const children = childItems.map((child: IWidgetItems, index: number) => {
      const trackingData: WidgetTrackingData = {
        namespace0: get(item, 'inventory_page_type', ''),
        namespace1: get(item, 'inventory_page_section', ''),
        namespace2: get(child, 'parameters.tracking_parameters', ''),
        position1: get(item, 'position'),
        position2: get(child, 'positionInParent') || index + 1, // Use index+1 value if position2 is missing
        transaction_id: getTransactionId(child),
        inventoryPageData: get(item, 'inventory_page_data'),
      };
      return {
        ...child,
        trackingData,
      };
    });
    return {
      ...item,
      widget_data: {
        ...item.widget_data,
        children,
        trackingData: trackingDataParent,
      },
    };
  });
};
