// constants
import { TRACK_TYPE } from 'shared/helpers/analytics/constants';
import { HOME_PAGE_EVENTS } from 'shared/helpers/analytics/constants/homePage';

// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

interface OnPageLoadArgs {
  NDNPageType: string;
  isGamificationEnabled?: boolean;
}

export const onPageLoad = ({ NDNPageType, isGamificationEnabled }: OnPageLoadArgs) => {
  NFA.track({
    type: TRACK_TYPE.PAGELOAD,
    event: HOME_PAGE_EVENTS.PAGELOAD,
    data: { page: NDNPageType, isGamificationEnabled: isGamificationEnabled },
  });
};
