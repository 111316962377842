// lib
import { getCleanUrl } from 'shared/helpers/utils/url';
import escape from 'lodash/escape';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// constants
import { IS_FILTERED_URL } from 'shared/layouts/Listing/Api/constants';
import { log } from 'shared/helpers/newRelic';
import { TAG_IDENTIFIER } from 'shared/helpers/newRelic/constants';

// local var
let commonImpressionsData: Record<string, any> | null = null;

export const appendURLParameters = (url: string, param: string) => {
  const params = new URLSearchParams(param);
  const cleanUrl = getCleanUrl(url);

  if (cleanUrl.indexOf('?') > -1) {
    return `${cleanUrl}&${params.toString()}`;
  }

  return `${cleanUrl}?${params.toString()}`;
};

export const PINNED_PROD_IDS_SEARCHTERM = 'pinned_product_id';

export const getPinnedProdcutsFromSearch = (existingSearch = ''): string => {
  const searchParams = new URLSearchParams(existingSearch);

  if (searchParams.has(PINNED_PROD_IDS_SEARCHTERM)) {
    // @ts-ignore
    return escape(searchParams.get(PINNED_PROD_IDS_SEARCHTERM));
  }

  return '';
};

export const getIsFilteredUrl = (existingSearch = ''): boolean => {
  const searchParams = new URLSearchParams(existingSearch);

  if (searchParams.has(IS_FILTERED_URL)) {
    // @ts-ignore
    return Boolean(escape(searchParams.get(IS_FILTERED_URL)));
  }

  return false;
};

/*
 ** generates impression data containing listing information
 ** which has no depenedency on product or any other page information
 ** this data is unlikely to change based on product card etc.
 */

export const storeImpressionsCommon = (listingData) => {
  let transformedImpressionsData = null;
  try {
    // default search page, eg. Black Sarees, etc.
    transformedImpressionsData = {
      page_search_term: get(listingData, 'response.title', ''),
      page_type: 'search_listing',
      tracking_metadata: get(listingData, 'response.tracking_metadata', {}),
    };

    // brand listing page
    if (get(listingData, 'response.is_brand', false)) {
      transformedImpressionsData = {
        page_brand_name: get(listingData, 'response.title', ''),
        page_type: 'brand_listing',
        hybrid_page_type: get(listingData, 'response.ad_platform_data.page-type', ''),
        page_brand_id: get(listingData, 'response.category_id', ''),
        tracking_metadata: get(listingData, 'response.tracking_metadata', {}),
      };
    }

    // category listing page, eg. Dresses, etc.
    if (
      get(listingData, 'response.entity_type', '') === 'category' ||
      !isEmpty(get(listingData, 'response.category_id', ''))
    ) {
      transformedImpressionsData = {
        hybrid_page_type: get(listingData, 'response.ad_platform_data.page-type', ''),
        tracking_metadata: get(listingData, 'response.tracking_metadata', {}),
        page_type: 'category_listing',
        page_category_id: get(listingData, 'response.category_id', ''),
        page_category_name: get(listingData, 'response.title', ''),
      };
    }

    if (transformedImpressionsData) {
      commonImpressionsData = transformedImpressionsData;
      return true;
    }
    return false;
  } catch (error) {
    log({
      errTitle: 'Error in transforming impressions data',
      error,
      tags: { [TAG_IDENTIFIER.MODULE]: 'PLP_IMPRESSIONS' },
    });
    return false;
  }
};

export const getImpressionsCommon = () => {
  return commonImpressionsData || {};
};
